$(document).ready(function(){
	fullheight();
	$(window).load(function(){
		
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		// CUSTOM FUNCTIONS
		fullheight();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
		responsiveSlick();
		customJava();
		objLightcase();
		equalizer();
		AOS.init();

		$('.loader-overlay').fadeOut(200);

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $footerHeight );
		fullheight();
		customJava();
		objLightcase();
		equalizer();
		AOS.init();

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
}
else{
}

function fullheight(){

	var $fullpage = $(window).height();
	$('.homepage_slider_wrapper .slider-item').css('height', $fullpage);
	$('.property-single-innerpage-banner .content').css('height', $fullpage);
	$('.find-property-innerpage-banner .content').css('height', $fullpage);
	$('.search-innerpage-banner .content').css('height', $fullpage);
	$('.location-innerpage-banner .content').css('height', $fullpage);
	$('.404page-layout .content').css('height', $fullpage);
}

function customJava(){
	$('.gallery-frame-wrapper').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  infinite: false,
	  fade: true,
	  asNavFor: '.gallery-navigation-wrapper'
	});
	$('.gallery-navigation-wrapper').slick({
	  slidesToShow: 5,
	  slidesToScroll: 1,
	  asNavFor: '.gallery-frame-wrapper',
	  dots: false,
	  infinite: false,
	  arrows: true,
	  nextArrow: '<div class="slick-arrow-div-right"><i class="fa fa-chevron-right"></i></div>',
      prevArrow: '<div class="slick-arrow-div-left"><i class="fa fa-chevron-left"></i></div>',
	  centerMode: false,
	  focusOnSelect: true
	});

	$('.powered-by-gallery').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  infinite: false,
	  fade: true,
	  asNavFor: '.powered-gallery-ctrl'
	});
	$('.powered-gallery-ctrl').slick({
	  slidesToShow: 4,
	  slidesToScroll: 1,
	  asNavFor: '.powered-by-gallery',
	  dots: false,
	  infinite: false,
	  arrows: false,
	  centerMode: false,
	  focusOnSelect: true
	});

	$('.properties-to-cities-wrapper .navigation-buttons .fa-chevron-left').on('click', function(){
		$('.properties-to-cities-wrapper .home_prop_to_cities_property_list button.slick-prev').click();
	});
	$('.properties-to-cities-wrapper .navigation-buttons .fa-chevron-right').on('click', function(){
		$('.properties-to-cities-wrapper .home_prop_to_cities_property_list button.slick-next').click();
	});

	$('.related-projects-wrapper .navigation-buttons .fa-chevron-left').on('click', function(){
		$('.related-projects-wrapper .related-wrapper button.slick-prev').click();
	});
	$('.related-projects-wrapper .navigation-buttons .fa-chevron-right').on('click', function(){
		$('.related-projects-wrapper .related-wrapper button.slick-next').click();
	});

	$('.quick-tips-wrapper .navigation-buttons .fa-chevron-left').on('click', function(){
		$('.quick-tips-wrapper .quick-tips-list-wrap button.slick-prev').click();
	});
	$('.quick-tips-wrapper .navigation-buttons .fa-chevron-right').on('click', function(){
		$('.quick-tips-wrapper .quick-tips-list-wrap button.slick-next').click();
	});

	$('.properties-to-sell-wrapper .navigation-buttons .fa-chevron-left').on('click', function(){
		$('.properties-to-sell-wrapper .home_prop_to_sell_property_list button.slick-prev').click();
	});
	$('.properties-to-sell-wrapper .navigation-buttons .fa-chevron-right').on('click', function(){
		$('.properties-to-sell-wrapper .home_prop_to_sell_property_list button.slick-next').click();
	});

	$('.properties-to-seen-wrapper .navigation-buttons .fa-chevron-left').on('click', function(){
		$('.properties-to-seen-wrapper .home_prop_to_seen_property_list button.slick-prev').click();
	});
	$('.properties-to-seen-wrapper .navigation-buttons .fa-chevron-right').on('click', function(){
		$('.properties-to-seen-wrapper .home_prop_to_seen_property_list button.slick-next').click();
	});

	$('.item-title').each(function(){
		$(this).on('click', function(){
		});
	});

  setTimeout(function(){
    $('#popup-modal').modal('show');
  }, 2000);
}